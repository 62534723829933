import { setEnviroment } from "./redux/slices/enviromentSlice/EnviromentSlice";
import { useUbicationFilterContext } from "./hooks/UbicationFilterContext";
import { useAppDispatch, useAppSelector } from "./hooks/reduxHooks";
import { useHotelDataContext } from "./hooks/HotelDataContextHook";
import React, { useCallback, useEffect, useState } from "react";
import useQuery from "./hooks/useQuery";
import { isProd } from ".";
import "./App.css";
import {
  selectAdults,
  selectChildrensAgesList,
} from "./redux/slices/enviromentSlice/EnviromentSlice.selectors";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import UpdateUrlParams from "./hooks/UpdateUrlParams";
import styles from "./App.module.scss";
import moment from "moment";
import { useLanguage } from "./hooks/languageContextHooks";
import useSentryUtils from "./hooks/useSentryUtils";
import { apiGetBookingEngineDefaultData } from "./api/getbookingenginedefaultdata/getbookingenginedefaultdata";

import Fallback from "./pages/fallback/Fallback";
import { useWidgetConfigContext } from "./contexts/widgetConfig/WidgetConfig.context";

import resetStyles from "./styles/utils/resetMixin.module.scss";
import { useShadowDomContext } from "./contexts/ShadowDom.context";
// import { toast, Toaster } from "react-hot-toast";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShadowPortal from "./ShadowPortal";
import { WidgetConfigProps } from "./types/widgetConfig.types";

const Home = React.lazy(() => import("./pages/home/Home"));
const ErrorPage = React.lazy(() => import("./pages/error/Error"));
const Payment = React.lazy(() => import("./pages/payment/Payment"));
const PaymentComplete = React.lazy(
  () => import("./pages/paymentComplete/PaymentComplete")
);
const PaymentError = React.lazy(
  () => import("./pages/paymentError/PaymentError")
);
const Property = React.lazy(() => import("./pages/property/Property"));

export enum APP_PAGES {
  HOME = "home",
  PROPERTY = "property",
  PAYMENT = "payment",
  PAYMENT_COMPLETE = "paymentComplete",
  PAYMENT_ERROR = "paymentError",
  ERROR_PAGE = "error",
}

export const ERROR_TYPES: {
  NO_GMAP_TOKEN: "noGmapToken";
  NO_ENVIRONMENT_ID: "noEnvironmentId";
} = {
  NO_GMAP_TOKEN: "noGmapToken",
  NO_ENVIRONMENT_ID: "noEnvironmentId",
};

export type ERRORS_TYPES_TYPE = (typeof ERROR_TYPES)[keyof typeof ERROR_TYPES];

const App: React.FC<WidgetConfigProps & { className?: string }> = (props) => {
  // redux hooks
  const dispatch = useAppDispatch();
  const adults = useAppSelector(selectAdults);
  const { shadowRoot } = useShadowDomContext();

  const childrenAgesList = useAppSelector(selectChildrensAgesList);

  // library hooks
  const queryParams = useQuery();
  const { t } = useTranslation();

  // custom hooks
  const { fetchHotelDataList, filteredHotelDataList: hotelDataList } =
    useHotelDataContext();
  const { fetchUbicationFilterList } = useUbicationFilterContext();
  const { updateUrlParams } = UpdateUrlParams();
  const { captureError } = useSentryUtils();

  // local hooks
  const [page, setPage] = useState<APP_PAGES | string[]>(APP_PAGES.HOME);
  const [loading, setLoading] = useState(true);
  const { defaultNights, hotelsId } = useWidgetConfigContext();
  const [enviromentId] = useState(
    queryParams.get("environment") ?? props.environmentId
  );
  const [error, setError] = useState<ERRORS_TYPES_TYPE>();

  // const language = queryParams.get("language");
  const { language } = useLanguage();

  useEffect(() => {
    if (shadowRoot) {
      if (!isProd) {
        const styles = document.querySelectorAll("style[availroom-app-styles]");
        styles.forEach((style) => {
          const clonedStyle = style.cloneNode(true) as HTMLStyleElement;
          shadowRoot.insertBefore(clonedStyle, shadowRoot.firstChild);
        });
      }
    }
  }, [shadowRoot]);

  useEffect(() => {
    dispatch(
      setEnviroment({
        environmentDataList: [enviromentId],
      })
    );
  }, [dispatch, enviromentId, props.divId]);

  const urlParamPage = queryParams.get("approute");

  const initialCall = useCallback(async () => {
    const adaptGoogleChildrenUrlParams = () => {
      const urlAges = queryParams.getAll("age");
      if (!(urlAges.length > 0)) return null;
      const formattedAges = urlAges
        .sort((a, b) => {
          const ageAIndex = a.split("_")[0];
          const ageBIndex = b.split("_")[0];
          return +ageAIndex - +ageBIndex;
        })
        .map((urlAge) => +urlAge.split("_")[1]);
      updateUrlParams({
        updateChildrenAgeList: formattedAges,
        replace: true,
        removeAges: true,
      });
      return formattedAges;
    };
    try {
      const urlParamAdults = queryParams.get("adults");
      const urlParamChildrenAges =
        adaptGoogleChildrenUrlParams() ||
        queryParams
          .get("childrenAges")
          ?.split(",")
          .map((age) => +age);
      let urlParamIniDate = queryParams.get("iniDate");
      let urlParamEndDate = queryParams.get("endDate");
      // const urlParamLanguage = queryParams.get("language");
      const urlParamZoneId = queryParams.get("zoneId");
      const urlChannelId = queryParams.get("channelid");
      const urlRoomType = queryParams.get("roomtype");
      const urlSearchHotel = queryParams.get("searchHotel");
      const urlTourist = queryParams.get("tourist");
      const response = await apiGetBookingEngineDefaultData({
        userLang: language,
        environmentDataId: enviromentId,
        ...(hotelsId && {
          hotelList: hotelsId,
        }),
        ...(urlChannelId && { channelId: urlChannelId }),
      });
      const resData = response.data;
      const clientData = resData.clientDataList[0];
      if (!resData.ok || !clientData.serverPath) {
        const error = new Error();
        (error as any).details = {
          response: response,
          error: new Error("bad response data"),
        };
        throw error;
      }
      const getGmapToken = () => {
        if (props.gmapToken) return props.gmapToken;
        return process.env.REACT_APP_GOOGLE_MAPS_API;
      };

      const gmapToken = getGmapToken();
      let iniDateMoment: moment.Moment = moment(
        clientData.fromDate,
        "DD-MM-YYYY"
      );
      let endDateMoment: moment.Moment = moment(
        clientData.toDate,
        "DD-MM-YYYY"
      );
      // let endDateMoment: moment.Moment = urlParamEndDate
      //   ? moment(urlParamEndDate, "DD-MM-YYYY")
      //   : moment().add(defaultNights, "days");

      if (defaultNights) {
        iniDateMoment = moment();
        endDateMoment = moment().add(defaultNights, "days");
      }

      if (urlParamIniDate) {
        iniDateMoment = moment(urlParamIniDate, "DD-MM-YYYY");
      }

      if (urlParamEndDate) {
        endDateMoment = moment(urlParamEndDate, "DD-MM-YYYY");
      }

      if (
        iniDateMoment.isBefore(moment(), "day") ||
        endDateMoment.isBefore(moment(), "day")
      ) {
        iniDateMoment = moment();
        endDateMoment = moment().add(defaultNights, "days");
        updateUrlParams({
          updateIniDate: iniDateMoment.format(),
          updateEndDate: endDateMoment.format(),
        });
      }
      if (endDateMoment.isSameOrBefore(iniDateMoment, "day")) {
        endDateMoment = iniDateMoment.clone().add(defaultNights, "days");
        updateUrlParams({
          updateEndDate: endDateMoment.format(),
        });
      }
      setLoading(true);
      if (!gmapToken) return setError(ERROR_TYPES.NO_GMAP_TOKEN);
      if (!enviromentId) return setError(ERROR_TYPES.NO_ENVIRONMENT_ID);

      dispatch(
        setEnviroment({
          ...clientData,
          iniDate: iniDateMoment.format() ?? clientData.fromDate,
          endDate: endDateMoment.format() ?? clientData.toDate,
          environmentDataList: [enviromentId],
          adults: urlParamAdults ? +urlParamAdults : undefined,
          childrenAgeList: urlParamChildrenAges ?? [],
          zoneId: urlParamZoneId ?? "",
          userLang: language,
          showLastRoomMessageInfo: clientData.showLastRoomMessageInfo,
          channelId: urlChannelId ?? undefined,
          tourist: urlTourist !== "false" ? true : false,
          roomtype: urlRoomType ?? undefined,
          searchHotel: urlSearchHotel ?? undefined,
        })
      );
      await fetchUbicationFilterList({
        serverPath: clientData.serverPath,
        environmentId: enviromentId,
      });
      if (props.type === "monounit") {
        await fetchHotelDataList({
          serverPath: clientData.serverPath,
          iniDate: iniDateMoment.format() ?? clientData.fromDate,
          endDate: endDateMoment.format() ?? clientData.toDate,
          enviromentId: [enviromentId],
          userLang: language,
          zoneId: urlParamZoneId ?? "",
          adults: urlParamAdults ? +urlParamAdults : adults,
          childrenAges: urlParamChildrenAges ?? childrenAgesList,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      captureError(error, { description: "error from App.tsx/initialCall" });
      setPage([APP_PAGES.ERROR_PAGE]);
      setError(t("errors.an_error_has_ocurred"));
      setLoading(false);
    }
  }, [
    adults,
    childrenAgesList,
    dispatch,
    enviromentId,
    fetchHotelDataList,
    fetchUbicationFilterList,
    props.clientIdentifier,
    props.defaultMargin,
    props.design,
    props.hideLanguageSelector,
    props.hideRating,
    props.type,
    props.gmapToken,
    props.paymentDefaultData,
    props.showSearchHotelInput,
    props.languages,
    props.locationDropdownDesign,
    queryParams,
    captureError,
    updateUrlParams,
    t,
    language,
  ]);

  useEffect(() => {
    if (error) return;
    initialCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, language]);

  useEffect(() => {
    if (urlParamPage) {
      setPage(urlParamPage.split("/"));
    } else {
      setPage([APP_PAGES.HOME]);
    }
  }, [urlParamPage]);

  const handleMonounit = () => {
    const propertyId = props.unitId ?? hotelDataList[0]?.id;

    updateUrlParams({
      updatePage: `${APP_PAGES.PROPERTY}/${propertyId}`,
      updateIniDate: queryParams.get("iniDate") ?? undefined,
      updateEndDate: queryParams.get("endDate") ?? undefined,
      updateAdults: queryParams.get("adults")
        ? +queryParams.get("adults")!
        : undefined,
      updateChildrenAgeList:
        queryParams
          .get("childrenAges")
          ?.split(",")
          .map((age) => +age) ?? undefined,
      replace: true,
    });
    return <Property loading={loading} propertyId={propertyId} />;
  };

  const router = () => {
    if (error) {
      switch (error) {
        case ERROR_TYPES.NO_GMAP_TOKEN:
          return (
            <ErrorPage
              errorMsg={t("errors.noGmapToken")}
              errorType={ERROR_TYPES.NO_GMAP_TOKEN}
            />
          );
        case ERROR_TYPES.NO_ENVIRONMENT_ID:
          return <ErrorPage errorMsg={t("errors.no_enviroment")} />;
        default:
          return <ErrorPage />;
      }
    }
    if (loading) return <Fallback />;
    switch (page[0]) {
      case APP_PAGES.HOME:
        if (props.type === "monounit") return handleMonounit();
        return <Home environmentId={enviromentId} />;
      case APP_PAGES.PROPERTY:
        return <Property loading={loading} propertyId={page[1]} />;
      case APP_PAGES.PAYMENT:
        return <Payment />;
      case APP_PAGES.PAYMENT_COMPLETE:
        return <PaymentComplete />;
      case APP_PAGES.PAYMENT_ERROR:
        return <PaymentError />;
      case APP_PAGES.ERROR_PAGE:
        return <ErrorPage />;
      default:
        return <ErrorPage />;
    }
  };

  return (
    <div className={`${props.className} ${styles.App}`}>
      <Helmet>
        <meta
          name="description"
          content={t("officialWebPagePriceAndAvailability")}
        />
      </Helmet>
      <ShadowPortal>
        <ToastContainer />
      </ShadowPortal>
      {router()}
    </div>
  );
};

export default App;
