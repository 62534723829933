import axios from "axios";
import { v4 } from "uuid";
import { amenityI } from "../../redux/slices/enviromentSlice/EnviromentSlice.type";

export interface apiGetBookingEngindeDefaultDataParams {
  userLang: string;
  environmentDataId: string;
  channelId?: string;
  hotelList?: string[];
}

export interface apiGetBookingEngindeDefaultDataResponse {
  clientDataList: Array<{
    borderColor: string;
    color: string;
    serverPath: string;
    showChildren: boolean;
    showInfants: boolean;
    showTagsInSearch: false;
    fromDate: string;
    toDate: string;
    amenitiesList: amenityI[];
    hotelTypeList: amenityI[];
    showLastRoomMessageInfo: boolean;
  }>;
  ok: true;
}

// axiosRetry(authAxios, {
//   retries: 3,
//   retryCondition: () => true,
// });

export const apiGetBookingEngineDefaultData = (
  data: apiGetBookingEngindeDefaultDataParams
) => {
  return axios.post<apiGetBookingEngindeDefaultDataResponse>(
    "https://api.availroom.com/sialdPMS_Channel/service/resourcescrossorigin/webcrossorigin/getbookingenginedefaultdata",
    { ...data, requestId: v4() },
    {
      headers: {
        Authorization: process.env.REACT_APP_API_AUTHORIZATION_TOKEN,
      },
    }
  );
};
