import React, { createContext, useCallback, useContext } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { selectServerPath } from "../redux/slices/enviromentSlice/EnviromentSlice.selectors";
import useSentryUtils from "./useSentryUtils";
import { useAppSelector } from "./reduxHooks";
import { apiGetFindZoneOrTag } from "../api/getfindzoneortag_api/getfindzoneortag_api";
import { useWidgetConfigContext } from "../contexts/widgetConfig/WidgetConfig.context";

export interface zoneI {
  id: string;
  name: string;
  hotelDataList?: zoneI[];
  type: "zone" | "hotel";
  zoneDataList?: zoneI[];
  neesting: number;
}

interface contextI {
  ubicationFilterList: zoneI[];
  fetchUbicationFilterList: (enviromentData?: {
    serverPath: string;
    environmentId: string;
  }) => Promise<void>;
  loading: boolean;
  error: string;
}

const ubicationFilterContext = createContext({} as contextI);

const UbicationFilterContext = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { hotelsId } = useWidgetConfigContext();
  const { t } = useTranslation();
  const [ubicationFilterList, setUbicationFilterList] = useState<zoneI[]>([
    { id: "", name: t("all_locations"), type: "zone", neesting: 0 },
  ]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const serverPath = useAppSelector(selectServerPath);

  const { captureError } = useSentryUtils();

  const formattedZoneDataList = useCallback(
    (zones: zoneI[], nestedCounter?: number) => {
      return zones.map((zone) => {
        let neestingCounter = nestedCounter === undefined ? 0 : nestedCounter;
        let hotelNestingCounter = 1;
        let newZone: zoneI = {
          ...zone,
          type: "zone",
          neesting: neestingCounter,
        };
        if (zone.hotelDataList?.length) {
          hotelNestingCounter = hotelNestingCounter + neestingCounter;
          newZone = {
            ...newZone,
            hotelDataList: zone.hotelDataList.map((hotel) => ({
              ...hotel,
              type: "hotel",
              neesting: hotelNestingCounter,
            })),
          };
        }
        if (zone.zoneDataList) {
          neestingCounter += 1;
          newZone = {
            ...newZone,
            zoneDataList: formattedZoneDataList(
              zone.zoneDataList,
              neestingCounter
            ).map((zone) => ({ ...zone, neesting: neestingCounter })),
          };
        }
        return newZone;
      });
    },
    []
  );

  const fetchUbicationFilterList = useCallback(
    async (enviromentData?: { serverPath: string; environmentId: string }) => {
      try {
        setLoading(true);
        const ubicationFiltersResponse = await apiGetFindZoneOrTag({
          serverPath: enviromentData?.serverPath ?? serverPath,
          data: {
            environmentDataId: enviromentData?.environmentId!,
            ...(hotelsId && { hotelList: hotelsId }),
          },
        });
        if (ubicationFiltersResponse.data.ok) {
          let newUbicationFilterList: zoneI[] = [
            { id: "", name: t("all_locations"), type: "zone", neesting: 0 },
          ];
          if (ubicationFiltersResponse.data.zoneDataList) {
            const newZoneDataList = formattedZoneDataList(
              ubicationFiltersResponse.data.zoneDataList
            );
            newUbicationFilterList = [
              ...newUbicationFilterList,
              ...newZoneDataList,
            ];
          }
          if (ubicationFiltersResponse.data.hotelDataList) {
            const newHotelDataList: zoneI[] =
              ubicationFiltersResponse.data.hotelDataList.map((hotel) => ({
                ...hotel,
                type: "hotel",
              }));
            newUbicationFilterList = [
              ...newUbicationFilterList,
              ...newHotelDataList,
            ];
          }
          setUbicationFilterList(newUbicationFilterList);
        }
      } catch (error: any) {
        console.log(error);
        captureError(error, {
          description:
            "error from UbicationFilterContext.tsx/fetchUbicationFilterList",
        });
        if (!error.response.data)
          return setError(t("errors.an_error_has_ocurred"));
        setError(error.response.data.error);
      }
      setLoading(false);
    },
    [captureError, formattedZoneDataList, serverPath, t]
  );

  return (
    <ubicationFilterContext.Provider
      value={{
        ubicationFilterList,
        fetchUbicationFilterList,
        loading,
        error,
      }}
    >
      {children}
    </ubicationFilterContext.Provider>
  );
};

export const useUbicationFilterContext = () =>
  useContext(ubicationFilterContext);

export default UbicationFilterContext;
